// FOCO
const API = 'https://focoapi.geniusws.com.br';
// const API = 'https://apifoco.geniusws.com.br';

// Masterop
// const API = 'https://apimasterop.geniusws.com.br';

// GENIUS
// const API = 'https://api.geniusws.com.br';

// MMC
// const API = 'https://api.mmcturismo.com';
// const API = 'http://apimmc.geniusws.com.br';

// Agentetur
//  const API = 'https://api.agente.tur.br'; //// usando este;
// const API = 'http://apiagentetur.geniusws.com.br';

// Personal
// const API = 'http://apipersonal.geniusws.com.br';
// const API = 'http://api.personal.tur.br';

// CRM
// const API = 'https://apicrmfrontur.geniusws.com.br';

// AgentGO
// const API = 'https://api.agentgo.tur.br';

// Oikos
// const API = 'https://apioikos.geniusws.com.br';

// MagicWay
// const API = 'http://apimagicway.geniusws.com.br';

// ARK
// const API = 'https://api.ark.tur.br';

// POINT TUR
// const API = 'https://api.pointtur.com.br';


export const environment = {
  production: true,
  API: API,
  PAYPAL_CLIENT_ID: "", ambiente: "GENIUS",
  THEME: "", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  USA_CASAMENTO: false,
  USA_CONTRATO: false,
  USA_CIRCUITO: false,
  USA_TURISMO: false,
  USA_PACOTE: false,
  USA_SEGURO: false,
  USA_DISNEY: false,
  USA_CARRO: false,
  USA_AEREO: false,
  USA_LOJA: false,
  USA_CASA: false,
  USA_HOTEL: false,
  USA_BETO: false,
  USA_PROMOCIONAL: false,
  USA_MARKUPIMPRESSAO: false,
  USA_ARKBEDS: false,
  USA_CADASTROAGENCIA: false,
  USA_SOLICITARDESCONTO: false,
  USA_TERMOMETRO: false,
  USA_CADASTRO_CIRCUITO: false,
  //Apis dos módulos de turismo
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  TURISMO_CARRO: API,
  TURISMO: API,
  GERENCIAMENTO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: false, // Permite Gerar Lâminas no portal
  USA_BANNERS: false, //Permite o uso de Banners

  //Configurações
  VALIDAPRAZOCANCELAMENTOMANUTENCAO: false,//foco estava reclamando disso.
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  MAPBOX_ACCESSTOKEN: "",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  TAG_GOOGLE: "",
  URLSITE: ''
};
